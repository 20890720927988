import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { ChurchModel } from './church.model';
import { MassModel } from './mass.model';
import { LocalData } from './localdata';
import * as $ from 'jquery';


@Injectable({
  providedIn: 'root'
})
export class LibsService {

  constructor(
    private router: Router,
    private sanitized: DomSanitizer,
    private _localDB: LocalData,
  ) { }

  loading(){
    // $('.bg-loading').addClass('loading');
  }

  unLoading(){
    $('.bg-loading').removeClass('loading');
  }

  getTimestamp(){
    return new Date().getTime();
  }

  convertDate2Timestamp(date){
    var tempDate = date.split('/');
    var newDate = new Date(tempDate[1].trim() + ',' + tempDate[0].trim() + ',' + tempDate[2].trim()).getTime();
    console.log(newDate);
    return newDate;
  }

  getURL(){
    var urlParams = window.location.href;
    var website = this._localDB.website;
    if (urlParams.indexOf('localhost') >= 0){
      website = window.location.protocol + "//" + window.location.host;
    }
    return website;
  }

  getSantizeHTML(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }

  getSantizeUrl(url : string) {
    return this.sanitized.bypassSecurityTrustUrl(url);
  }

  getLocalStorageItem(key){
    // return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : [];
    return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : [];
  }

  setLocalStorageItem(key, value){
    // localStorage.setItem(key, JSON.stringify(value));
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  removeLocalStorageItem(item){
    // localStorage.removeItem(item);
    sessionStorage.removeItem(item);
  }

  dynamicSortByName(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }

  dynamicSortByNameSort(property, sort) {
    // sort : des/asc
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result
        if (sort == 'asc'){
          result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
        }else{
          result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        }
        return result * sortOrder;
    }
  }

  dynamicSortMultiple() {
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;
        while(result === 0 && i < numberOfProperties) {
            result = this.dynamicSortByName(props[i])(obj1, obj2);
            i++;
        }
        return result;
    }
  }

  go2Page(page){
    this.router.navigate(['/'+page]);
  }

  go2PageWithParam(page, param){
    this.router.navigate(['/'+page+'/'+param]);
  }


  similarArr(arr1, arr2){
    let temp = [];
    // arr1 = [{id:a, icon: abc}, {id: b, icon: abcd}, {id: d, icon: abcde}];
    // arr2 = [a, b, c, d, e];
    for (var i = 0; i < arr1.length; ++i) {
      if (arr2.indexOf(arr1[i]['id']) >= 0){
        temp.push(arr1[i]);
      }
    }
    return temp;
  }

  generatePassword(){
    return Math.random().toString(36).slice(-8);
  }
  
  getInitialBG(n){
    return this._localDB.bgColor[n];
  }

  convertSize(fileSize){
    return fileSize < 1024000
        ? (fileSize / 1024).toFixed(2) + " KB"
        : (fileSize / 1024000).toFixed(2) + " MB";
  }

  addRemoveItem4Array(id, arry, isNum){
    if (isNum){
      id = parseInt(id);
    }
    // let tempArr = [];
    let index = arry.indexOf(id);
    if (index > -1) {
      arry.splice(index, 1);
    }else{
      arry.push(id);
    }
    return arry;
  }

  accentsTidy = function(s){
    var r=s.toLowerCase();
    r = r.replace(new RegExp("\\s", 'g'),"");
    r = r.replace(new RegExp("[àáâãäå]", 'g'),"a");
    r = r.replace(new RegExp("æ", 'g'),"ae");
    r = r.replace(new RegExp("ç", 'g'),"c");
    r = r.replace(new RegExp("[èéêë]", 'g'),"e");
    r = r.replace(new RegExp("[ìíîï]", 'g'),"i");
    r = r.replace(new RegExp("ñ", 'g'),"n");                            
    r = r.replace(new RegExp("[òóôõö]", 'g'),"o");
    r = r.replace(new RegExp("œ", 'g'),"oe");
    r = r.replace(new RegExp("[ùúûü]", 'g'),"u");
    r = r.replace(new RegExp("[ýÿ]", 'g'),"y");
    r = r.replace(new RegExp("\\W", 'g'),"");
    return r;
  };

  remove_accents(strAccents) {
    var strAccents = strAccents.split('');
    var strAccentsOut = new Array();
    var strAccentsLen = strAccents.length;
    var accents =    "ÀÁÂÃÄÅẬàáâãäåậÒÓÔÕÕÖỚØòóôõöøớÈÉÊËỄẾèéêëễếðÇçÐÌÍÎÏìíîïÙÚÛÜỨùúûüứÑñŠšŸÿýŽž";
    var accentsOut = "AAAAAAAaaaaaaaOOOOOOOooooooooEEEEEEeeeeeeeCcDIIIIiiiiUUUUUuuuuuNnSsYyyZz";
    for (var y = 0; y < strAccentsLen; y++) {
        if (accents.indexOf(strAccents[y]) != -1) {
            strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
        } else
            strAccentsOut[y] = strAccents[y];
    }
    var newStrAccentsOut = strAccentsOut.join('');
    return newStrAccentsOut;
  }

  removeAccentsVN(str){
      str = str.toLowerCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      // str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
      // str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
      // str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
      // str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
      // str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
      // str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
      // str = str.replace(/Đ/g, "D");
      return str;
  }

  // BEGIN CONVERTER
  convert2OriginalId(id){
    return id.replace(/C-n/g,'');
  }

  convertTimestamp2Date(timestamp){
    var a = new Date(timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    return month + ' ' + date + ' ' + year;
  }

  convertTimestamp2Time(timestamp){
    var a = new Date(timestamp * 1000);
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    return hour + ':' + min + ':' + sec;
  }

  convert2NewIdString(pass){
    let passLength = pass.length;
    let times = Math.floor(passLength / 5);
    let newPass = '';
    let bStr = 0;
    let eStr = 0;
    for (var i = 0; i < times; ++i) {
      bStr = i * 5;
      eStr = bStr + 5;
      newPass += pass.slice(bStr, eStr)+'C-n'; 
    }
    newPass += pass.slice((times * 5), passLength); 
    return newPass;
  }

  convert2ChurchModel(church){
    let tempChurch =  new ChurchModel();
    if (church['address']       != '' && church['address']       != undefined){ tempChurch['address']       = church['address']; }else{ tempChurch['address'] = ''}
    if (church['city']          != '' && church['city']          != undefined){ tempChurch['city']          = church['city']; }else{ tempChurch['city'] = ''}
    if (church['description']   != '' && church['description']   != undefined){ tempChurch['description']   = church['description']; }else{ tempChurch['description'] = ''}
    if (church['email']         != '' && church['email']         != undefined){ tempChurch['email']         = church['email']; }else{ tempChurch['email'] = ''}
    if (church['name']          != '' && church['name']          != undefined){ tempChurch['name']          = church['name']; }else{ tempChurch['name'] = ''}
    if (church['phone']         != '' && church['phone']         != undefined){ tempChurch['phone']         = church['phone']; }else{ tempChurch['phone'] = ''}
    if (church['state']         != '' && church['state']         != undefined){ tempChurch['state']         = church['state']; }else{ tempChurch['state'] = ''}
    if (church['zipcode']       != '' && church['zipcode']       != undefined){ tempChurch['zipcode']       = church['zipcode']; }else{ tempChurch['zipcode'] = ''}
    if (church['website']       != '' && church['website']       != undefined){ tempChurch['website']       = church['website']; }else{ tempChurch['website'] = ''}
    if (church['timestamp']     != '' && church['timestamp']     != undefined){ tempChurch['timestamp']     = church['timestamp']; }else{ tempChurch['timestamp'] = 0}
    if (church['active']        != '' && church['active']        != undefined){ tempChurch['active']        = church['active']; }else{ tempChurch['active'] = true}
    return tempChurch
  }

  convert2MassModel(mass){
    let tempMass =  new MassModel();
    if (mass['name']          != '' && mass['name']          != undefined){ tempMass['name']          = mass['name']; }else{ tempMass['name'] = ''}
    if (mass['churchId']      != '' && mass['churchId']      != undefined){ tempMass['churchId']      = mass['churchId']; }else{ tempMass['churchId'] = ''}
    if (mass['link']          != '' && mass['link']          != undefined){ tempMass['link']          = mass['link']; }else{ tempMass['link'] = ''}
    if (mass['date']          != '' && mass['date']          != undefined){ tempMass['date']          = mass['date']; }else{ tempMass['date'] = ''}
    if (mass['time']          != '' && mass['time']          != undefined){ tempMass['time']          = mass['time']; }else{ tempMass['time'] = ''}
    if (mass['timezone']      != '' && mass['timezone']      != undefined){ tempMass['timezone']      = mass['timezone']; }else{ tempMass['timezone'] = ''}
    if (mass['timestamp']     != '' && mass['timestamp']     != undefined){ tempMass['timestamp']     = mass['timestamp']; }else{ tempMass['timestamp'] = 0}
    if (mass['live']          != '' && mass['live']          != undefined){ mass['live']              = mass['live']; }else{ mass['live'] = false}
    return tempMass
  }

  /// BEGIN MODAL
  closeModal(){
    $('.modal-bg').removeClass('modal-backdrop fade in')
    $('.modal').removeClass('in');
    $('body').removeClass('modal-open');
    return 'none';
  }

  openModal(mId){
    $('body').addClass('modal-open');
    $('.modal').addClass('hide');
    $('.modal#'+mId).addClass('in').removeClass('hide');
    $('.modal-bg').addClass('modal-backdrop fade in')
    return 'block';
  }

  /// END MODAL

  /// VALIDATION
  makeRequirementField(){
    var fields = [];
    $('.form-group').each(function(e){
      if ($(this).hasClass('required')){
        var target = $(this).find('input, select');
        var id = target.attr('id');
        fields.push(id);
        var labelText = $(this).find('label').text();
        $(this).find('label').html(labelText + ' <span class="font-red">(*)</span>')
      }
    });
    return fields;
  }

  validatorFields(fields){
    var num = 0;
    for (var i = 0; i < fields.length; ++i) {
      var target = $('#'+fields[i]);
      var val = target.val();
      if (target.val() == null){
        val = '';
      }
      if (val == '' ){
        if (!target.parent().hasClass('error')){
          target.parent().addClass('error');
          target.parent().append('<span class="form-text text-muted font-red small error-label">This field is required.</span>');
        }
        num++;
      }else{
        target.parent().find('.error-label').remove();
      }
    }
    if (num > 0) return true
    else return false;
  }

}
