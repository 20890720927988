<!-- BEGIN: LAYOUT/BREADCRUMBS/BREADCRUMBS-1 -->
<div class="c-layout-breadcrumbs-1 c-fonts-uppercase c-fonts-bold">
    <div class="container">
        <div class="c-page-title c-pull-left">
            <h3 class="c-font-uppercase c-font-sbold">{{church['name']}}</h3>
            <h4>{{church['city']}}, {{church['state']}}</h4>
        </div>
       
    </div>
</div>
<!-- END: LAYOUT/BREADCRUMBS/BREADCRUMBS-1 -->

<!-- BEGIN: CURRENT VIDEO -->
<div class="c-content-box c-size-sm">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="video-item mb-3">
					<div class="embed-responsive embed-responsive-16by9">
						<iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(mass['fullurl'])" title="{{mass['name']}}" class="embed-responsive-item" [ngClass]="mass['iframe']"></iframe>
					</div>
					<div class="mt-2">
						<p class="mb-0">{{mass['name']}}</p>
						<span class="d-block c-font-13">Mass's date on {{mass['date']}} ({{mass['timezone']}})</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- BEGIN: CURRENT VIDEO -->

<!-- BEGIN: ALL MASSES LISTING FOR CHURCH -->
<div class="c-content-box c-size-md">
	<div class="container">
		<div class="row">
			<div class="col-md-9 col-sm-12">
				<div class="c-content-title-1 wow animate fadeInDown" style="visibility: visible; animation-name: fadeInDown; opacity: 1;">
					<h3 class="c-font-uppercase c-left c-font-bold mb-2">Another Masses</h3>
					<div class="c-line-left c-bg-red"></div>
				</div>
				<div class="row" *ngIf="churchMasses.length > 0">
					<div class="col-md-6 col-sm-12" *ngFor="let mass of churchMasses">
						<div class="video-items mb-3">
							<div class="embed-responsive embed-responsive-16by9">
								<span class="c-live-stream c-label c-bg-red c-font-uppercase c-font-white c-font-13 c-font-sbold" *ngIf="mass['live']">Live</span>
								<a class="c-mass-overlay" [routerLink]="['/mass', mass['id']]"></a>
								<iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(mass['fullurl'])" title="{{mass['name']}}" class="embed-responsive-item" ></iframe>
							</div>
							<div class="">
								<p class="mb-0">{{mass['name']}}</p>
								<span class="d-block c-font-13">Mass's date on {{mass['date']}} ({{mass['timezone']}})</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row" *ngIf="churchMasses.length == 0">
					<div class="col-12 c-center">
						<p>No more mass for '<b>{{church['name']}}</b>' church at this time</p>
					</div>
				</div>
			</div>
			<div class="col-md-3 col-sm-12">
				<div class="c-content-ver-nav">
					<div class="c-content-title-1 c-theme c-title-md c-margin-t-40">
						<h3 class="c-font-bold c-font-uppercase">Churches List</h3>
						<div class="c-line-left c-bg-red"></div>
					</div>
					<ul class="c-menu c-arrow-dot1 c-theme">
						<li *ngFor="let church of anotherChurches">
							<a class="c-mass-overlay" [routerLink]="['/church', church['id']]">
								{{church['name']}} - <span class="small">{{church['city']}}, {{church['state']}}</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>

	</div>
</div>