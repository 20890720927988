import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Observable, of, Subject } from 'rxjs';
import { first, map, delay, take, tap } from 'rxjs/operators';

import { VideoModel } from './video.model'

@Injectable({
  providedIn: 'root'
})
export class MysqlService {
  // baseUrl = 'http://localhost/uploads';
  baseUrl = 'http://api.doleconnections.com';
  constructor(
    private http: Http,
    private httpClient: HttpClient,
  ) { }

  //MEDIA

  //Upload File to PHP Server
  postImage(file, filename, cId){
    let content = new FormData();
    content.append('image', file, filename+'|'+cId);
    let headers = new Headers({
                                'Accept': 'application/json', 
                                'Authorization': '61f53a76d009475a909b2c2ded448f1f'
                              });
    let options = new RequestOptions({headers: headers});
    return this.http.post(this.baseUrl+'/post_image.php', content, options).pipe(map((response:any) => {
      return {
        data: JSON.parse(response['_body'])
      };
    }));
      // .subscribe(event => {
      //   // console.log(event);
      //   if (event.statusText == 'OK'){
      //     return 'Upload Successful';
      //   }else{
      //     return 'Upload Unsuccessful';
      //   }
      // });
  }

  updateImage(file, filename, cId){
    let content = new FormData();
    content.append('image', file, filename+'|'+cId);
    let headers = new Headers({
                                'Accept': 'application/json', 
                                'Authorization': '61f53a76d009475a909b2c2ded448f1f'
                              });
    let options = new RequestOptions({headers: headers});
    return this.http.post(this.baseUrl+'/update_image.php', content, options).pipe(map((response:any) => {
      return {
        data: JSON.parse(response['_body'])
      };
    }));
  }

  //Upload URL Image to PHP Server
  postUrlImage(params: UrlFileData): Observable<UrlFileData> {
    return this.http.post(this.baseUrl+"/post_image_url.php", params).pipe(map((response:any) => {
      return {
        url: params.url,
        categoryId: params.categoryId,
        data: JSON.parse(response['_body'])
      };
    }));
  }

  getImageById(id){
    return this.http.get(this.baseUrl+'/get_image.php?id='+id);
  }

  getAllImages(){
    return this.http.get(this.baseUrl+'/get_images.php');
  }

  getAllImagesByCategory(id: string){
    return this.http.get(this.baseUrl+"/get_images_by_category.php?id="+ id);
  }

  deleteAllImagesByCategory(id: string): Observable<any> {
    return this.http.get(this.baseUrl+"/delete_images_by_category.php?id="+ id);
  }

  deleteImage(id: string): Observable<any> {
    return this.http.get(this.baseUrl+"/delete_image.php?id="+ id);
  }
  
  /// VIDEO / VIRTUAL TOUR
  getVideoById(id){
    return this.http.get(this.baseUrl+"/get_videos.php?id="+id);
  }

  getVideos(){
    return this.http.get(this.baseUrl+"/get_videos.php");
  }

  getVideosByType(ty: string) {
    return this.http.get(this.baseUrl+"/get_videos_by_type.php?type="+ty);
  }

  postVideo(file, params: VideoModel): Observable<VideoData> {
    let content = new FormData();
    let  newParams = file.name+'|'+params.name+'|'+params.url+'|'+params.type;
    content.append('image', file, newParams);
    let headers = new Headers({
                                'Accept': 'application/json', 
                                'Authorization': '61f53a76d009475a909b2c2ded448f1f'
                              });
    let options = new RequestOptions({headers: headers});
    return this.http.post(this.baseUrl+'/post_video.php', content, options).pipe(map((response:any) => {
      return {
        id: response.id,
        name: params.name,
        url: params.url,
        type: params.type,
        data: JSON.parse(response['_body']),
      };
    }));
  }

  updateVideo(params: VideoData): Observable<VideoData> {
    return this.http.post(this.baseUrl+"/update_video.php", params).pipe(map((response:any) => {
      return {
        id: params.id,
        name: params.name,
        url: params.url,
        type: params.type,
        data: JSON.parse(response['_body'])
      };
    }));
  }

  updateVideoFile(file, params: VideoData): Observable<VideoData> {
    let content = new FormData();
    let  newParams = file.name+'|'+params.id+'|'+params.name+'|'+params.url+'|'+params.type;
    content.append('image', file, newParams);
    let headers = new Headers({
                                'Accept': 'application/json', 
                                'Authorization': '61f53a76d009475a909b2c2ded448f1f'
                              });
    let options = new RequestOptions({headers: headers});
    return this.http.post(this.baseUrl+'/update_video_file.php', content, options).pipe(map((response:any) => {
      return {
        id: params.id,
        name: params.name,
        url: params.url,
        type: params.type,
        data: JSON.parse(response['_body']),
      };
    }));
  }

  deleteVideo(id: string): Observable<any> {
    return this.http.post(this.baseUrl+"/delete_video.php", {id: id}).pipe(map((response:any) => {
      return {
        data: JSON.parse(response['_body'])
      };
    }));
  }

  ///SEND EMAIL
  emailApp(params): Observable<any> {
    return this.http.post(this.baseUrl+"/email_app.php", params).pipe(map((response:any) => {
      return {
        data: JSON.parse(response['_body'])
      };
    }));
  }
  
  emailNewAccount(params): Observable<any> {
    return this.http.post(this.baseUrl+"/email_new_account.php", params).pipe(map((response:any) => {
      return {
        data: JSON.parse(response['_body'])
      };
    }));
  }

  sendNewEmail(params): Observable<any> {
    return this.http.post(this.baseUrl+"/email_new.php", params).pipe(map((response:any) => {
      return {
        data: JSON.parse(response['_body'])
      };
    }));
  }

  // emailLending(params): Observable<any> {
  //   return this.http.post(this.baseUrl+"/email_lending.php", params).pipe(map((response:any) => {
  //     return {
  //       data: JSON.parse(response['_body'])
  //     };
  //   }));
  // }

  // emailSellYourHome(params): Observable<any> {
  //   return this.http.post(this.baseUrl+"/email_sell_your_home.php", params).pipe(map((response:any) => {
  //     return {
  //       data: JSON.parse(response['_body'])
  //     };
  //   }));
  // }

  // emailContact(params): Observable<any> {
  //   return this.http.post(this.baseUrl+"/email_contact.php", params).pipe(map((response:any) => {
  //     return {
  //       data: JSON.parse(response['_body'])
  //     };
  //   }));
  // }

}

export interface VideoData {
  id: string;
  name: string;
  url: string;
  type: string,
  data: any[],
}

export interface EmailData {
  email: string;
  password: string;
  data: any[];
}

export interface UrlFileData {
  url: string;
  categoryId: string;
  data: any[];
}
