import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as $ from 'jquery';

import { ChurchModel, ChurchService, 
        VideoTypeService, VideoTypeModel,
  		  MassModel, MassService,
  		  LibsService, PagerService, MysqlService, LocalData } from '../_services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  topVideo = 'https://www.youtube.com/watch?v=p6RWlgSb8kQ?rel=0';
  churches = [];
  masses = [];
  videoTypes = [];
  videoType: VideoTypeModel = new VideoTypeModel();

  massesResult = [];
  churchesResult = [];

  constructor(
    private _libs: LibsService,
  	private massService: MassService,
  	private churchService: ChurchService,
    private videoTypeService: VideoTypeService,
  	public sanitizer: DomSanitizer
  ) { 
    this.churches = this._libs.getLocalStorageItem('churches');
    this.videoTypes = this._libs.getLocalStorageItem('videoTypes');
    this.masses = this._libs.getLocalStorageItem('masses');
  }

  ngOnInit(): void {
    $('.c-layout-header-fixed').removeClass('c-church c-mass').addClass('c-home');

  	if (this.videoTypes.length == 0){
      this.getVideoTypesList();
    }
    if (this.churches.length == 0){
  		this.getChurchesList();
  	}else{
      this.churchesResult = this.churches;
  		if (this.masses.length == 0){
  			this.getMassesList();
  		}else{
  			this.getChurchInfo4Mass();
        // console.log(this.masses);
        // console.log(this.videoTypes);
  		}
  	}
  }

  //CHURCH
  getChurchesList(){
  	this.churchService.getChurchesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(churches => {
      this.churches = churches.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('churches', this.churches);
      // console.log(this.churches);
      this.churchesResult = this.churches;
      if (this.masses.length == 0){
		    this.getMassesList();
  	  }else{
		    this.getChurchInfo4Mass();
  	  }
    });
  }

  getChurch(id){
    let tempChurch = new ChurchModel();
    var temp = this.churches.filter(ft => ft['id'] == id);
    return temp[0];
  }

  getMassChurch(id){
    // console.log(id);
    var allMasses = this.getAllMasses4Church(id);
    if (allMasses.length > 0){
      this._libs.go2PageWithParam('church', allMasses[0]['id'])
    }
  }

  searchChurch(e){
    var term = this._libs.removeAccentsVN(e.target.value);
    term = term.toLowerCase();
    if (term.length > 2){
      var temp = [];
      for (var i = 0; i < this.churches.length; ++i) {
        var tempName = this._libs.removeAccentsVN(this.churches[i]['name']);
        if (tempName.indexOf(term) >= 0){
          temp.push(this.churches[i]);
        }
      }
      this.churchesResult = temp;
    }else{
      this.churchesResult = this.churches;
    }
    // console.log(this.churchesResult);
  }

  //MASS
  getMassesList(){
  	this.massService.getMassesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(masses => {
      this.masses = masses.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('masses', this.masses);
      this.getChurchInfo4Mass();
      // console.log(this.masses);
    });
  }

  getChurchInfo4Mass(){
    for (var i = 0; i < this.masses.length; ++i) {
      var tempVT = this.getVideoType(this.masses[i]['videotype']);
  		this.masses[i]['fullurl'] = tempVT['url'] + this.masses[i]['link'] + tempVT['param1'];
  		this.masses[i]['church'] = this.getChurch(this.masses[i]['churchId']);
      this.masses[i]['accname'] = this._libs.removeAccentsVN(this.masses[i]['name']);
  	}
  	this.masses = this.masses.sort(this._libs.dynamicSortByNameSort('timestamp', 'asc'));
    this.massesResult = this.masses;
  }

  getAllMasses4Church(id){
    var temp = this.masses.filter(ft => ft['churchId'] == id);
    return temp;
  }

  searchMass(e){
    var term = this._libs.removeAccentsVN(e.target.value);
    term = term.toLowerCase();
    if (term.length > 2){
      var temp = [];
      for (var i = 0; i < this.masses.length; ++i) {
        var tempName = (this.masses[i]['accname']).toLowerCase();
        if (tempName.indexOf(term) >= 0){
          temp.push(this.masses[i]);
        }
      }
      this.massesResult = temp;
    }else{
      this.massesResult = this.masses;
    }
    // console.log(this.massesResult)
  }

  //VIDEO TYPE 
  getVideoTypesList(){
    this.videoTypeService.getVideoTypesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(VideoTypes => {
      this.videoTypes = VideoTypes.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('videoTypes', this.videoTypes);
      // console.log(this.videoTypes);
    });
  }

  getVideoType(id){
    let tempVT = new VideoTypeModel();
    var temp = this.videoTypes.filter(ft => ft['id'] == id);
    return temp[0];
  }

  selectVideoType(e){
    this.videoType = this.getVideoType(e.target.value);
  }

}
