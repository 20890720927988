<!-- BEGIN: LAYOUT/HEADERS/HEADER-1 -->
<!-- BEGIN: HEADER -->
<header class="c-layout-header c-layout-header-4 c-layout-header-default-mobile" data-minimize-offset="80">
    <div class="c-topbar c-topbar-dark d-none">
        <div class="container-fluid">
            <!-- BEGIN: INLINE NAV -->
            <nav class="c-top-menu c-pull-left">
                <ul class="c-icons c-theme-ul">
                    <li><a href="#"><i class="icon-social-twitter"></i></a></li>
                    <li><a href="#"><i class="icon-social-facebook"></i></a></li>
                    <li><a href="#"><i class="icon-social-dribbble"></i></a></li>
                    <li class="hide"><span>Phone: (312) 394-9858</span></li>
                </ul>
            </nav>
            <!-- END: INLINE NAV -->
            <!-- BEGIN: INLINE NAV -->
            <nav class="c-top-menu c-pull-right">
                <ul class="c-links c-theme-ul">
                    <li><a routerLink = "/" class="c-font-thin">Help</a></li>
                    <li class="c-divider">|</li>
                    <li><a routerLink = "/contact" class="c-font-thin">Contact</a></li>
                    <li class="c-divider">|</li>
                    <li><a routerLink = "/aboutus" class="c-font-thin">About Us</a></li>
                </ul>
                <ul class="c-ext c-theme-ul">
                    <li class="c-lang dropdown c-last">
                        <a href="#" class="c-font-thin">VN</a>
                        <ul class="dropdown-menu pull-right" role="menu">
                            <li class="active"><a href="#">Vietnamese</a></li>
                            <li><a href="#">English</a></li>
                            <li><a href="#">Espaniol</a></li>
                        </ul>
                    </li>
                    <li class="c-search d-none">
                        <!-- BEGIN: QUICK SEARCH -->
                        <form action="#">
                            <input type="text" name="query" placeholder="search..." value="" class="form-control" autocomplete="off">
                            <i class="fa fa-search"></i>
                        </form>
                        <!-- END: QUICK SEARCH -->  
                    </li>
                </ul>
            </nav>
            <!-- END: INLINE NAV -->
        </div>
    </div>
    <div class="c-navbar">
        <div class="container">
            <!-- BEGIN: BRAND -->
            <div class="c-navbar-wrapper clearfix c-center">
                <div class="c-brand">
                    <a href="/" class="c-logo">
                        <img src="assets/base/img/layout/logos/logo-114.png" alt="{{ siteName }}" class="c-desktop-logo">
                        <img src="assets/base/img/layout/logos/logo-72.png" alt="{{ siteName }}" class="c-desktop-logo-inverse">
                        <img src="assets/base/img/layout/logos/logo-57.png" alt="{{ siteName }}" class="c-mobile-logo"> 
                    </a>
                    <button class="c-hor-nav-toggler btn-menu d-none" type="button" data-target=".c-mega-menu" (click)="showMegaMenu()">
                        <span class="c-line"></span>
                        <span class="c-line"></span>
                        <span class="c-line"></span>
                    </button>
                    <button class="c-topbar-toggler btn-menu d-none" type="button" (click)="showExpandedMenu()">
                        <i class="fa fa-ellipsis-v"></i>
                    </button>
                    <button class="c-search-toggler btn-menu d-none" type="button">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
                <!-- END: BRAND -->
                <!-- BEGIN: QUICK SEARCH -->
                <form  class="c-quick-search" method="post" target="_blank" [formGroup] = "searchForm" (keyup.enter) = "onSearchFormSubmit()">
                    <input type="text" name="searchkey" id="searchkey" formControlName="searchkey" placeholder="Type to search..." class="form-control" autocomplete="off">
                    <span class="c-theme-link">&times;</span>
                </form>
                <!-- END: QUICK SEARCH -->
                <!-- BEGIN: HOR NAV -->
                <!-- BEGIN: LAYOUT/HEADERS/MEGA-MENU -->
                <!-- BEGIN: MEGA MENU -->
                <!-- Dropdown menu toggle on mobile: c-toggler class can be applied to the link arrow or link itself depending on toggle mode -->
                <nav class="c-mega-menu c-pull-right c-mega-menu-dark c-mega-menu-dark-mobile c-theme c-fonts-uppercase c-fonts-bold d-none">
                    <ul class="nav navbar-nav c-theme-nav">
                        <li class="c-menu-type-classic" [routerLinkActive]="['c-active']" [routerLinkActiveOptions]="{ exact: true }">
                            <a routerLink = "/home" class="c-link">Trang chủ</a>
                        </li>
                        <li class="c-search-toggler-wrapper d-none">
                            <a href="#" class="c-btn-icon c-search-toggler">
                                <i class="fas fa-search"></i>
                            </a>
                        </li>
                        
                        <li class="d-none">
                            <a href="javascript:;" data-toggle="modal" data-target="#login-form" class="c-btn-border-opacity-04 c-btn btn-no-focus c-btn-header btn btn-sm c-btn-border-1x c-btn-white c-btn-circle c-btn-uppercase c-btn-sbold">
                                <i class="icon-user"></i> Sign In
                            </a>
                        </li>
                        
                    </ul>
                </nav>
                <!-- END: MEGA MENU -->
                <!-- END: LAYOUT/HEADERS/MEGA-MENU -->
                <!-- END: HOR NAV -->
            </div>
            <!-- BEGIN: LAYOUT/HEADERS/QUICK-CART -->
           
            <!-- END: LAYOUT/HEADERS/QUICK-CART -->
        </div>
    </div>
</header>
<!-- END: HEADER -->
<!-- END: LAYOUT/HEADERS/HEADER-1 -->

<!-- BEGIN: PAGE CONTAINER -->
<div class="c-layout-page">
<!-- BEGIN: PAGE CONTENT -->
  <router-outlet></router-outlet>
<!-- END: PAGE CONTENT -->
</div>
<!-- END: PAGE CONTAINER -->
<!-- BEGIN: LAYOUT/FOOTERS/FOOTER-1 -->
<a name="footer"></a>
<footer class="c-layout-footer c-layout-footer-1">
    <div class="c-postfooter py-2">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <p class="c-copyright c-font-14 my-1"> Copyright &copy; <span id="current-year"></span> {{siteName}}. All rights reserved.</p>
                    <p class="c-font-regular c-font-14 my-1">Developed by <a class="c-font-regular" href="http://www.dothang.net" target="_blank">www.dothang.net</a></p>
                </div>
                <div class="col-md-6 col-sm-6 d-none">
                    <ul class="c-socials">
                        <li>
                            <a href="#">
                                <i class="icon-social-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="icon-social-facebook"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="icon-social-youtube"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="icon-social-dribbble"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- END: LAYOUT/FOOTERS/FOOTER-1 -->
<!-- BEGIN: LAYOUT/FOOTERS/GO2TOP -->
<div class="c-layout-go2top" style="display: none;">
    <i class="icon-arrow-up"></i>
</div>
<!-- END: LAYOUT/FOOTERS/GO2TOP -->
<!-- BEGIN: LAYOUT/BASE/BOTTOM -->
