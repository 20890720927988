import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

import { ChurchModel } from './church.model';

@Injectable({
  providedIn: 'root'
})
export class ChurchService {
  churchesRef: AngularFireList<ChurchModel> = null;

  constructor(
  	private afData: AngularFireDatabase,
  ) {
  	this.churchesRef = afData.list('/churches');
  }

  getChurchesList(): AngularFireList<ChurchModel> {
    return this.churchesRef;
  }

  getChurch(id): AngularFireList<ChurchModel> {
    return this.afData.list('/churches/'+id);
  }

  updateChurch(key, dev) {
    this.churchesRef.set(key, dev);
  }

  createChurch(church: ChurchModel): void {
    this.churchesRef.push(church);
  }

  deleteChurch(key: string): void {
    this.churchesRef.remove(key).catch(error => this.handleError(error));
  }

  private handleError(error) {
    console.log(error);
  }
}
