export class VideoTypeModel {
  name: string;
  url: string;
  param1: string;

  constructor(){
    this.name = '';
    this.url = '';
    this.param1 = '';
  }
}