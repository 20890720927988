<!-- BEGIN: LAYOUT/TOP IMAGE -->
<div class="-content-box c-size-sm">
    <div class="c-top-banner" style="background-image: url('../../assets/base/img/content/backgrounds/bg-02.jpg');">
    	<div class="c-display-table c-posistion-relative">
    		<div class="c-display-table-cell c-vertical-bottom">
    			<div class="container-fluid">
    				<div class="row justify-content-end">
    					<div class="col-5 d-none d-sm-block">
		    				<div class="c-font-uppercase c-top-text">
			    				<img src="../../assets/imgs/papa-francesco-text.png" class="img-fluid">
			    			</div>
		    			</div>
	    			</div>
	    		</div>
	    	</div>
	    </div>
	</div>
</div>
<!-- END: LAYOUT/TOP IMAGE -->

<!-- BEGIN: PAGE CONTENT -->
<!-- BEGIN: ALL MASSES LISTING -->
<div class="c-content-box c-size-sm">
	<div class="c-search-box d-block">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-12 py-3 mb-3">
					<div class="embed-responsive embed-responsive-16by9">
						<iframe [src]="sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/OOLZDaTgIaM?autoplay=1&rel=0&controls=1')" title="The Veil Removed" class="embed-responsive-item" frameborder="0"></iframe>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div class="container">
		<div class="row justify-content-between">
			<div class="col-md-8 colsm-12">
				<div class="c-content-title-1 wow animate fadeInDown" style="visibility: visible; animation-name: fadeInDown; opacity: 1;">
					<h3 class="c-font-uppercase c-left c-font-sbold mb-2">All Latest Masses</h3>
					<div class="c-line-left c-bg-red"></div>
				</div>
			</div>
			<div class="col-md-4 col-sm-12 c-search-container mb-3">
				<form action="#" method="post">
					<div class="input-group">
				      <input type="text" class="form-control c-square c-theme-border" placeholder="Search mass..." (keyup)="searchMass($event)">
				      <span class="input-group-btn">
				        <button class="btn c-theme-btn c-theme-border c-btn-square" type="button">Go!</button>
				      </span>
				    </div>
				</form>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4 col-sm-12" *ngFor="let mass of massesResult">
				<div class="video-items mb-3">
					<div class="embed-responsive embed-responsive-16by9">
						<span class="c-live-stream c-label c-bg-red c-font-uppercase c-font-white c-font-13 c-font-sbold" *ngIf="mass['live']">Live</span>
						<a class="c-mass-overlay" [routerLink]="['/mass', mass['id']]"></a>
						<iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(mass['fullurl'])" title="{{mass['name']}}" class="embed-responsive-item"></iframe>
					</div>
					<div class="">
						<p class="mb-0">{{mass['name']}}</p>
						<a [routerLink]="['/church', mass['churchId']]" class="d-block" class="c-font-sbold">{{mass.church['name']}}</a>
						<span class="d-block c-font-13">Mass's date on {{mass['date']}} ({{mass['timezone']}})</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- BEGIN: ALL CHURCHES LISTING -->
<div class="c-content-box c-size-md">
	<div class="container">
		<div class="row justify-content-between">
			<div class="col-md-8 colsm-12">
				<div class="c-content-title-1 wow animate fadeInDown" style="visibility: visible; animation-name: fadeInDown; opacity: 1;">
					<h3 class="c-font-uppercase c-left c-font-sbold mb-2">All Churches</h3>
					<div class="c-line-left c-bg-red"></div>
				</div>
			</div>
			<div class="col-md-4 col-sm-12 c-search-container mb-3">
				<form action="#" method="post">
					<div class="input-group">
				      <input type="text" class="form-control c-square c-theme-border" placeholder="Search church..." (keyup)="searchChurch($event)">
				      <span class="input-group-btn">
				        <button class="btn c-theme-btn c-theme-border c-btn-square" type="button">Go!</button>
				      </span>
				    </div>
				</form>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<div class="c-content-ver-nav">
					<ul class="c-menu c-arrow-dot1 c-theme">
						<li *ngFor="let church of churchesResult">
							<a [routerLink]="['/church', church['id']]" class="d-block" class="c-font-sbold">
								<span class="c-font-sbold c-font-18">{{church['name']}}</span> - <span class="">{{church['city']}}, {{church['state']}}</span>
							</a>
						</li>
					</ul>
				</div>
			</div>	
		</div>
	</div>
</div>