import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

import { MassModel } from './mass.model';

@Injectable({
  providedIn: 'root'
})
export class MassService {

  massesRef: AngularFireList<MassModel> = null;

  constructor(
  	private afData: AngularFireDatabase,
  ) {
  	this.massesRef = afData.list('/masses');
  }

  getMassesList(): AngularFireList<MassModel> {
    return this.massesRef;
  }

  getMass(id): AngularFireList<MassModel> {
    return this.afData.list('/masses/'+id);
  }

  updateMass(key, dev) {
    this.massesRef.set(key, dev);
  }

  createMass(mass: MassModel): void {
    this.massesRef.push(mass);
  }

  deleteMass(key: string): void {
    this.massesRef.remove(key).catch(error => this.handleError(error));
  }

  private handleError(error) {
    console.log(error);
  }

}
