import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { TypeModel } from './type.model';

@Injectable({
  providedIn: 'root'
})
export class TypeService {

  typeRef: AngularFireList<TypeModel> = null;

  constructor(
  	private router: Router,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
  ) {
  	this.typeRef = afData.list('/types');
  }

  getTypesList(): AngularFireList<TypeModel> {
    return this.typeRef;
  }

  getType(id): AngularFireList<TypeModel> {
    return this.afData.list('/types/'+id);
  }

  updateType(key, pkg) {
    this.typeRef.set(key, pkg);
  }

  createType(pkg: TypeModel): void {
    this.typeRef.push(pkg);
  }

  deleteType(key: string): void {
    this.typeRef.remove(key).catch(error => this.handleError(error));
  }

  deleteAll(): void {
    this.typeRef.remove().catch(error => this.handleError(error));
  }
  
  private handleError(error) {
    console.log(error);
  }
}
