import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

import * as $ from 'jquery';
import { LibsService, LocalData } from './_services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  website = this._libs.getURL();
  siteName = this._localDB.sitename;
  wLogo = this._localDB.wlogo;
  logo = this._localDB.logo;

  searchForm: FormGroup;
  
  currentRoute = '';

  constructor(
		public router: Router,
		private formBuilder:FormBuilder,
  		private http: Http,
  		private requestOptions: RequestOptions,
    private _localDB: LocalData,
  		private _libs: LibsService
	){
		this.searchForm = this.formBuilder.group({
    		searchkey: new FormControl('', Validators.required),
  		});
		router.events.subscribe(event => {
	      	if (event instanceof NavigationEnd ) {
	      		this.currentRoute = (event.url).replace('/','');
	      		var indx = this.currentRoute.indexOf('/');
	      		if (indx > 0){
	      			this.currentRoute = this.currentRoute.substring(0,indx);
	      			// console.log(this.currentRoute);
	      		}
		    };
	    });
	}  

  onSearchFormSubmit(){
 //  	let searchKey = this.searchForm.controls['searchkey'].value;
	// this.getAllResults('tieude', searchKey);
  }

  showMegaMenu(){
	// console.log('app');
	if ($('body').hasClass('c-mega-menu-shown')){
		$('body').removeClass('c-mega-menu-shown');
		$('.c-mega-menu').removeClass('c-shown');
	}else{
		$('.c-layout-header-fixed').addClass('c-mega-menu-shown');
		$('.c-mega-menu').addClass('c-shown');
	}
  }

  showExpandedMenu(){
	if ($('body').hasClass('c-topbar-expanded')){
		$('body').removeClass('c-topbar-expanded');
	}else{
		$('body').addClass('c-topbar-expanded');
	}
  }
  
}
