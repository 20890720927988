import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';

import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireDatabaseModule, AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { VideoTypeModel } from './videotype.model';

@Injectable({
  providedIn: 'root'
})
export class VideoTypeService {

  videotypeRef: AngularFireList<VideoTypeModel> = null;

  constructor(
  	private router: Router,
    private afStore: AngularFirestore,
    private afData: AngularFireDatabase,
  ) {
  	this.videotypeRef = afData.list('/videotypes');
  }

  getVideoTypesList(): AngularFireList<VideoTypeModel> {
    return this.videotypeRef;
  }

  getVideoType(id): AngularFireList<VideoTypeModel> {
    return this.afData.list('/videotypes/'+id);
  }

  updateVideoType(key, pkg) {
    this.videotypeRef.set(key, pkg);
  }

  createVideoType(pkg: VideoTypeModel): void {
    this.videotypeRef.push(pkg);
  }

  deleteVideoType(key: string): void {
    this.videotypeRef.remove(key).catch(error => this.handleError(error));
  }

  deleteAll(): void {
    this.videotypeRef.remove().catch(error => this.handleError(error));
  }
  
  private handleError(error) {
    console.log(error);
  }
}
