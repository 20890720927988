export class ChurchModel {
  timestamp: number;
  name: string;
  address: string;
  zipcode: string;
  city: string;
  state: string;
  website: string;
  email: string;
  phone: string;
  active: boolean;
  description: string;

  constructor(){
    this.timestamp = 0;
    this.name = "";
    this.address = '';
    this.zipcode = '';
    this.city = "";
    this.state = "";
    this.website = "";
    this.email = '';
    this.phone = '';
    this.active = true;
    this.description = '';
  }
}
