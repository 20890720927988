import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as $ from 'jquery';

import { ChurchModel, ChurchService, 
          VideoTypeService, VideoTypeModel,
		  MassModel, MassService,
		  LibsService, PagerService, LocalData } from '../_services';

@Component({
  selector: 'app-mass',
  templateUrl: './mass.component.html',
  styleUrls: ['./mass.component.scss']
})
export class MassComponent implements OnInit {
  churches = [];
  church = new ChurchModel();
  churchId = '';
  masses = [];
  mass = new MassModel();
  massId = '';
  churchMasses = [];
  anotherChurches = [];

  videoTypes = [];
  videoType: VideoTypeModel = new VideoTypeModel();

  constructor(
	private route: ActivatedRoute,
    private _libs: LibsService,
  	private massService: MassService,
    private videoTypeService: VideoTypeService,
  	private churchService: ChurchService,
  	public sanitizer: DomSanitizer
  ) { 
    this.churches = this._libs.getLocalStorageItem('churches');
    this.masses = this._libs.getLocalStorageItem('masses');
    this.videoTypes = this._libs.getLocalStorageItem('videoTypes');
  }

  ngOnInit(): void {
    $('.c-layout-header-fixed').removeClass('c-church c-home').addClass('c-mass');
  	this.route.params.subscribe((params: Params) => {
      this.massId = params['id'];
      window.scroll(0, 0);
	    if (this.videoTypes.length == 0){
      	this.getVideoTypesList();
      }
	    if (this.churches.length == 0){
  		  this.getChurchesList();
  	  }else{
  		  if (this.masses.length == 0){
    			this.getMassesList();
    		}else{
    			this.getChurchInfo4Mass();
    		}
  	  }
    });
  }

  //CHURCH
  getChurchesList(){
  	this.churchService.getChurchesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(churches => {
      this.churches = churches.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('churches', this.churches);
      // console.log(this.churches);
      if (this.masses.length == 0){
		this.getMassesList();
  	  }else{
		this.getChurchInfo4Mass();
  	  }
    });
  }

  getChurch(id){
    var temp = this.churches.filter(ft => ft['id'] == id);
    return temp[0];
  }

  getMassChurch(id){
  	var allMasses = this.getAllMasses4Church(id);
  	if (allMasses.length > 0){
  		this._libs.go2PageWithParam('church', allMasses[0]['id'])
  	}
  }

  getAnotherChurches(){
  	var temp = this.churches.filter(ft => ft['id'] != this.churchId);
  	return temp;
  }

  //MASS
  getMassesList(){
  	this.massService.getMassesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(masses => {
      this.masses = masses.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('masses', this.masses);
      this.getChurchInfo4Mass();
      // console.log(this.masses);
    });
  }

  getMass(id){
    var temp = this.masses.filter(ft => ft['id'] == id);
    return temp[0];
  }

  getAllMasses4Church(id){
  	var temp = this.masses.filter(ft => ft['churchId'] == id);
  	temp = temp.filter(ft => ft['id'] != this.massId);
  	return temp;
  }

  getChurchInfo4Mass(){
  	for (var i = 0; i < this.masses.length; ++i) {
      var tempVT = this.getVideoType(this.masses[i]['videotype']);
	  this.masses[i]['fullurl'] = tempVT['url'] + this.masses[i]['link'] + tempVT['param1'];
  	  this.masses[i]['church'] = this.getChurch(this.masses[i]['churchId']);
  	  this.masses[i]['iframe'] = tempVT['name'].replace(/ /g,'-').toLowerCase();
  	}
  	this.masses = this.masses.sort(this._libs.dynamicSortByNameSort('timestamp', 'asc'));
    this.mass = this.getMass(this.massId);
    this.churchId = this.mass['churchId'];
    this.church = this.getChurch(this.churchId);
  	this.churchMasses = this.getAllMasses4Church(this.churchId);
  	this.anotherChurches = this.getAnotherChurches();
  	console.log(this.masses);
  }

  //VIDEO TYPE 
  getVideoTypesList(){
    this.videoTypeService.getVideoTypesList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(VideoTypes => {
      this.videoTypes = VideoTypes.sort(this._libs.dynamicSortByName('name'));
      this._libs.setLocalStorageItem('videoTypes', this.videoTypes);
      // console.log(this.videoTypes);
    });
  }

  getVideoType(id){
    let tempVT = new VideoTypeModel();
    var temp = this.videoTypes.filter(ft => ft['id'] == id);
    return temp[0];
  }

  selectVideoType(e){
    this.videoType = this.getVideoType(e.target.value);
  }

}
